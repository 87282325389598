<template>
  <!-- eslint-disable  -->
  <div>
    <router-view v-if="$route.name === 'mr子帳戶清單'" />
    <div v-if="$route.name === 'mr成交機率'">
      <v-card flat class="w100p"></v-card>

      <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
        {{ $store.getters['app/getRouteTitle'] }}
      </div>

      <v-card
        outlined
        :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
      >
        <v-card-text class="d-flex align-center">
          <v-spacer></v-spacer>
          <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

          <SizeBox width="14" />
        </v-card-text>

        <DataTable
          :labels="labels"
          :fields="headers"
          :list="result.list"
          :total="result.total"
          :loading="isLoading"
          :replace="['sub_channel']"
          @get-list="fetchData"
        >
          <template #item.sub_channel="{ item }">
            <div class="flex-center">
              <v-btn
                rounded
                @click="handleAction((vm) => item.sub_channel.action(vm, item))"
                color="Secondary050"
                class="mx-2 my-3"
                depressed
                small
                :to="item.sub_channel.to ? item.sub_channel.to(item) : null"
              >
                <v-icon v-if="!!item.sub_channel.icon" size="18" class="mr-1">
                  {{ item.sub_channel.icon }}
                </v-icon>
                {{ item.sub_channel.label }}
              </v-btn>
            </div>
          </template>
        </DataTable>
      </v-card>
    </div>

    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import { getTransactionSummary } from '@/api/merchantCenter/collectChannels';

const sub_channel = {
  label: '子帳戶清單',
  icon: 'mdi-cog-outline',
  action: (vm, v) => {
    // vm.openForm('Detail', v);
  },
  to: (v) => ({
    name: 'mr子帳戶清單',
    params: { channel_type_id: v.collect_channel_type.id }
  })
};

export default {
  async created() {
    this.fetchData();
  },

  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        {
          text: '通道類型',
          value: 'collect_channel_type.code',
          align: 'center',
          width: '120px'
        },
        // {
        //   text: '子帳戶名稱',
        //   value: 'sub_channel',
        //   align: 'center'
        // },
        {
          text: '提單總額',
          value: 'total_collect_order_amt',
          align: 'center'
        },
        {
          text: '代收手續費',
          value: 'total_collect_order_fee',
          align: 'center'
        },
        {
          text: '入帳總額',
          value: 'total_collect_order_credit_amt',
          align: 'center'
        },
        {
          text: '交易筆數',
          value: 'total_collect_orders',
          align: 'center'
        },
        {
          text: '交易成功率',
          value: 'success_rate',
          align: 'center'
        },
        {
          text: '成功筆數',
          value: 'total_successful',
          align: 'center'
        },
        {
          text: '失敗筆數',
          value: 'total_failed',
          align: 'center'
        }
      ],
      labels: {
        'collect_channel_type.code': [
          { text: '銀行轉帳', value: 'bank_transfer', color: 'Primary050' }
        ]
      }
    };
  },

  computed: {},

  methods: {
    async fetchData() {
      this.isLoading = true;

      const Summary = await getTransactionSummary();

      if (!Summary.error) {
        this.result.total = Summary.total;
        this.result.page = Summary.page;
        this.$set(
          this.result,
          'list',
          Summary.map((item) => ({
            ...item,
            success_rate:
              Number(this.$num.multiply(item.success_rate, 100)).toFixed(2) +
              '%',
            sub_channel
          }))
        );
      }

      this.isLoading = false;
    },

    handleAction(fn) {
      fn(this);
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
