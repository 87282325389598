import { api } from '..';

export function getCollectChannelList(payload) {
  payload = { page: 1, limit: 20, ...payload }
  return api('/v1/merchantCenter/collectChannels/getCollectChannelList', payload);
}

export function getTransactionSummary(payload) {
  return api('/v1/merchantCenter/collectChannels/getTransactionSummary', payload);
}

export function getCardSummary(payload) {
  return api('/v1/merchantCenter/collectChannels/getCardSummary', payload);
}
